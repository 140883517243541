import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"


const BlogPostTemplate = ({ data, location }) => {

  const post = data.webiny.getHummerEvPost.data
  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
        description={post.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className="">
          <img src={post.profileImage} className="featureImage" />
        </div>
        <header>
          <h1 itemProp="headline">{post.title}</h1>
        </header>
        <section
          itemProp="articleBody"
        >
        <span>Team: {post.team}</span>
        <p>{post.description}</p>
        </section>
          
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query PostBySlug(
      $slug: String
    ) {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      getHummerEvPost(where: {slug: $slug}) {
        data {
          id
          title
          team
          slug
          description
          profileImage
          socialYouTube
          socialTwitter
          socialInstagram
          socialFacebook
          socialTwitch
          socialTikTok
        }
      }
    } 
  }
`
